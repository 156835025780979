<template>
  <div class="sidebar-page">
    <h1 class="title is-3 mx-6">
      <span>Minőségellenőrzési terv {{ this.inspectionPlanLists.year }}</span>
      <b-button class="is-pulled-right"
                @click="hardRefreshTable"
                icon-left="redo-alt">
        Lista frissítése
      </b-button>
<!--      <b-button class="is-pulled-right"-->
<!--                @click="testMe"-->
<!--                icon-left="redo-alt">-->
<!--        Test Me-->
<!--      </b-button>-->
      <b-field class="is-pulled-right mt-2">
        <div class="control">
          <b-switch v-model="autoUpdate">Autómatikus frissítés</b-switch>
        </div>
      </b-field>
    </h1>
    <section class="sidebar-layout">
      <SideBar :items="inspectionPlanLists.lists"
               :current-list-id="currentListID"
               @updateLoading="(newState) => { this.isLoading = newState }"
               @addItemToTableData="(rows) => { this.addItemToTableData(rows) }"
               @selectInspectionPlanList="selectInspectionPlanList"/>
      <div class="sidebar-content mr-5">
        <InspectionPlanUniversalTable v-if="!!inspectionPlanTable"
                                      :table-data="inspectionPlanTable.data"
                                      :table-columns="inspectionPlanTable.columns"
                                      is-detailed>
          <template v-slot:action-block="props">
            <b-tooltip label="Törlés a tervből"
                       position="is-left">
              <b-button v-if="props.row.status !== 'deleted'" icon-left="trash-alt"
                        type="is-danger"
                        size="is-small"
                        @click="deleteFromPlan(props.row.id, props.row.name, props.row.result)"/>
              </b-tooltip>
            <b-tooltip label="Visszaállítás a tervbe"
                       position="is-left">
              <b-button v-if="props.row.status === 'deleted'" icon-left="redo-alt"
                        type="is-warning"
                        size="is-small"
                        @click="redoDeleteFromPlan(props.row.id, props.row.name, props.row.result)"/>
            </b-tooltip>
            <b-tooltip label="Megjegyzés"
                       position="is-left">
              <b-button type="is-success"
                        icon-left="comment-alt"
                        size="is-small"
                        @click="openInspectionPlanCommentModal(props.row.id, props.row.megjegyzes)"/>
            </b-tooltip>
          </template>
        </InspectionPlanUniversalTable>
      </div>
      <div>
        <Loading :is-active="isLoading"/>
      </div>
    </section>
  </div>
</template>

<script>
import { arrayToString, formattedDate } from '@/utils/utils'
import SideBar from '@/components/InspectionPlan/SideBar.vue'
import InspectionPlanUniversalTable from '@/components/InspectionPlan/InspectionPlanUniversalTable.vue'
import Loading from '@/components/Loading'

export default {
  name: 'Home',
  components: { InspectionPlanUniversalTable, SideBar, Loading },
  data: () => {
    return {
      defaultOpenedDetails: [],
      isLoading: false,
      searchText: '',
      currentListID: false,
      inspectionPlanLists: {
        year: null,
        lists: [],
      },
      inspectionPlanTable: false,
      autoUpdate: false,
    }
  },
  methods: {
    arrayToString,
    formattedDate,
    async hardRefreshTable () {
      // this.isLoading = true
      /// TODO: refresh list
      // await this.$store.dispatch('fetchInspectionList')
      await this.selectInspectionPlanList(this.currentListID)
      // this.isLoading = false
    },
    async autoRefreshTable () {
      if (this.autoUpdate && this.currentListID && this.inspectionPlanTable && this.inspectionPlanTable?.lastUpdate) {
        const response = await this.$store.dispatch('fetchInspectionPlanTableUpdatedRows', {
          listID: this.currentListID,
          lastUpdate: this.inspectionPlanTable.lastUpdate,
        })
        if (response && response.data && Array.isArray(response.data) && response.data.length > 0) {
          this.inspectionPlanTable.lastUpdate = response.sys_date
          this.updateTableData(response.data)
        }
      }
    },
    deleteFromPlan (id) {
      this.$store.dispatch('openModal', {
        modalName: 'ConfirmationModal',
        data: {
          text: 'Biztos törli a tervből?',
          confirmFunction: async () => {
            // TODO: delete plan api call...
            // this.$emit('deleteAssignment', id)
            const response = await this.$store.dispatch('deleteFromInspectionPlan', {
              rowID: id,
            })
            if (response) {
              this.updateTableData(response)
            }
          },
        },
      })
    },
    redoDeleteFromPlan (id) {
      this.$store.dispatch('openModal', {
        modalName: 'ConfirmationModal',
        data: {
          text: 'Biztos visszarakja a tervbe?',
          confirmFunction: async () => {
            // TODO: redo delete plan api call...
            // this.$emit('deleteAssignment', id)
            const response = await this.$store.dispatch('redoDeleteFromInspectionPlan', {
              rowID: id,
            })
            if (response) {
              this.updateTableData(response)
            }
          },
        },
      })
    },
    openInspectionPlanCommentModal (id, comment) {
      this.$store.dispatch('openModal', {
        modalName: 'InspectionPlanCommentModal',
        data: {
          currentComment: comment,
          currentTableID: this.currentListID,
          rowID: id,
          updateRows: (rows) => { this.updateTableData(rows) }
        },
      })
    },
    async selectInspectionPlanList (id) {
      this.currentListID = false
      this.inspectionPlanTable = false
      this.isLoading = true
      const inspectionPlanTable = await this.$store.dispatch('fetchInspectionPlanTable', id)
      if (inspectionPlanTable) {
        this.inspectionPlanTable = {
          data: inspectionPlanTable.data,
          columns: inspectionPlanTable.columns,
          lastUpdate: inspectionPlanTable.sys_date,
        }
        this.currentListID = id
      }
      this.isLoading = false
    },
    async testMe () {
      await this.$store.dispatch('inspectionPlanUniversalSearch', 'Nagy')
      // await this.$store.dispatch('fetchInspectionPlanTable', 1)
    },
    dynamicFunction (functionPayload) {
      alert(`dynamicFunction | ${functionPayload.functionName} | ${functionPayload.payload}`)
    },
    updateTableData (changedRows) {
      if (this.inspectionPlanTable) {
        this.inspectionPlanTable.data = this.inspectionPlanTable.data.map(row => {
          const changedRow = changedRows.find(changedRow => changedRow.id === row.id)
          return changedRow ? { ...row, ...changedRow } : row
        })
      }
    },
    addItemToTableData (newRows) {
      if (this.inspectionPlanTable) {
        this.inspectionPlanTable.data = [...this.inspectionPlanTable.data, ...newRows]
      }
    },
  },
  computed: {},
  async beforeCreate () {
    this.isLoading = true
    const inspectionPlanLists = await this.$store.dispatch('fetchInspectionPlanLists')
    if (inspectionPlanLists) {
      this.inspectionPlanLists.lists = inspectionPlanLists.lists
      this.inspectionPlanLists.year = inspectionPlanLists.year
      if (inspectionPlanLists.lists.length > 0) {
        await this.selectInspectionPlanList(inspectionPlanLists.lists[0].id)
      }
    }
    this.isLoading = false
  },
  created () {
    setInterval(() => {
      // console.log('call autoUpdate')
      this.autoRefreshTable()
    }, 10000)
  },
}
</script>

<style lang="scss"
       scoped>

.sidebar-page {
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 100%;
  // min-height: 100vh;
  .sidebar-layout {
    display: flex;
    flex-direction: row;
    min-height: 100%;
    // min-height: 100vh;
  }

  .sidebar-content {
    width: 100%;
  }
}
</style>
