<template>
  <div class="px-3 py-2 pointer-cursor hovered-background">
    <span v-if="icon" class="icon is-small mr-2">
      <i :class="`fas fa-${icon}`"/>
    </span>
    <span>
      <slot></slot>
    </span>
  </div>
</template>

<script>
export default {
  name: 'SideBarActionItem',
  props: {
    icon: {
      type: [String, Boolean],
      default: false,
    },
  },
}
</script>

<style scoped>

</style>
