<template>
  <b-sidebar
    position="static"
    :mobile="mobile"
    :expand-on-hover="expandOnHover"
    :reduce="reduce"
    :delay="expandWithDelay ? 500 : null"
    type="is-white"
    open
  >
    <div class="p-1">
      <b-menu class="is-custom-mobile">
        <b-menu-list :label="`Műveletek`">
          <SideBarActionItem icon="plus"
                             @click.native="openInspectionPlanAddMemberModal">
            Új elem hozzáadása
          </SideBarActionItem>
        </b-menu-list>
        <b-menu-list label="Listák">
          <SideBarActionItem v-for="(item, index) in items"
                             :key="`sidebarActionItem_${index}`"
                             icon="table"
                             :class="{
                               'is-selected-action-item': currentListId ? Number(item.id) === Number(currentListId) : false
                             }"
                             @click.native="selectInspectionPlanList(item.id)">
            {{ item.name }}
          </SideBarActionItem>
        </b-menu-list>
      </b-menu>
    </div>
  </b-sidebar>
</template>

<script>
import SideBarActionItem from '@/components/Inpsection/SideBar/SideBarActionItem'

export default {
  name: 'InspectionPlanSideBar',
  components: { SideBarActionItem },
  props: {
    items: {
      type: Array,
      required: true,
    },
    currentListId: {
      type: [Boolean, Number],
    }
  },
  data () {
    return {
      expandOnHover: false,
      expandWithDelay: false,
      mobile: 'reduce',
      reduce: false,
    }
  },
  methods: {
    selectInspectionPlanList (listID) {
      this.$emit('selectInspectionPlanList', listID)
    },
    async openInspectionPlanAddMemberModal () {
      this.$emit('updateLoading', true)
      const memberList = await this.$store.dispatch('fetchInspectionPlanMemberList')
      this.$emit('updateLoading', false)
      this.$store.dispatch('openModal',
        {
          modalName: 'InspectionPlanAddMemberModal',
          data: {
            currentListID: this.currentListId,
            memberList: memberList,
            planList: this.items,
            addItemToTableData: (rows) => { this.$emit('addItemToTableData', rows) }
          }
        })
    }
  }
}
</script>

<style lang="scss"
       scoped>
@import "../../assets/css/_custom-variables.scss";

.p-1 {
  padding: 1em;
}

.menu-list::v-deep a {

  white-space: nowrap !important;
  overflow: hidden;
  text-overflow: ellipsis;

}

@media screen and (max-width: 1023px) {
  .b-sidebar {
    .sidebar-content {
      &.is-mini-mobile {
        &:not(.is-mini-expand),
        &.is-mini-expand:not(:hover):not(.is-mini-delayed) {
          .menu-list {
            li {
              a {
                span:nth-child(2) {
                  display: none;
                }
              }

              ul {
                padding-left: 0;

                li {
                  a {
                    display: inline-block;
                  }
                }
              }
            }
          }

          .menu-label:not(:last-child) {
            margin-bottom: 0;
          }
        }
      }
    }
  }
}

@media screen and (min-width: 1024px) {
  .b-sidebar {
    .sidebar-content {
      &.is-mini {
        &:not(.is-mini-expand),
        &.is-mini-expand:not(:hover):not(.is-mini-delayed) {
          .menu-list {
            li {
              a {
                span:nth-child(2) {
                  display: none;
                }
              }

              ul {
                padding-left: 0;

                li {
                  a {
                    display: inline-block;
                  }
                }
              }
            }
          }

          .menu-label:not(:last-child) {
            margin-bottom: 0;
          }
        }
      }
    }
  }
}

.is-mini-expand {
  .menu-list a {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.is-selected-action-item {
  background: $blue;
  color: white;
}
</style>
