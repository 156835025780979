<template>
  <b-table :data="tableData"
           :loading="false"
           class="mb-6 has-table-success-header"
           default-sort="date"
           default-sort-direction="desc"
           sticky-header
           striped
           height="auto"
           :opened-detailed="defaultOpenedDetails"
           :detailed="isDetailed"
           detail-key="id"
           detail-transition="fade"
           :show-detail-icon="true"
           :paginated="tableData.length > itemPerPage"
           :per-page="itemPerPage"
           pagination-position="top"
           :row-class="(row, index) => {
                      return row.status === 'deleted'
                        ? 'has-background-grey-lighter has-text-grey-light'
                        : ''
                    }"
  >
    <b-table-column v-for="(item, index) of tableColumns.filter(item => item.visible)"
                    :key="`inspectionPlanMainTableRow_${index}`"
                    :field="item.key"
                    :label="item.name"
                    :sortable="item.sortable"
                    :searchable="item.searchable"
                    v-slot="props"
                    header-class="">
      <span v-if="item.type === 'text'">
        {{ props.row[item.key] }}
      </span>
      <span v-else-if="item.type === 'number'">
        {{ props.row[item.key] }}
      </span>
      <span v-else-if="item.type === 'boolean'">
        <b-icon v-if="props.row[item.key]"
                icon="check-square"/>
        <b-icon v-else
                pack="far"
                icon="square"/>
      </span>
      <span v-else-if="item.type === 'date'">
        {{ formattedDate(props.row[item.key]) }}
      </span>
      <b-tag v-else-if="item.type === 'status'"
             :type="getRowStatusTagType(props.row[item.key])"
             class="is-radiusless">
        {{ getRowStatusText(props.row[item.key]) }}
      </b-tag>
      <b-tag v-else-if="item.type === 'tag'"
             :type="`is-${props.row[item.key + '_tag_type']}`"
             class="is-radiusless">
        {{ props.row[item.key] }}
      </b-tag>
    </b-table-column>

<!--    <b-table-column v-if="$slots['action-block']">-->
    <b-table-column v-slot="props">
      <slot name="action-block" v-bind:row="props.row"></slot>
      <b-tooltip v-for="(key, index) of tableColumns.filter(item => item.type === 'function').map(item => item.key)"
                 :key="`inspectionPlanTableRowAction_${index}`"
                 v-show="!!props.row[key]"
                 :label="getRowFunctionTitle(props.row[key])"
                 position="is-left">
        <b-button :icon-left="getRowFunctionIcon(props.row[key])"
                  type="is-success"
                  size="is-small"
                  @click="dynamicFunction(props.row[key], props.row)"/>
      </b-tooltip>
    </b-table-column>

    <template #top-left>
<!--      <b-field label="Keresés">-->
<!--        <b-input v-model="searchText"-->
<!--                 type="search"-->
<!--                 icon="magnify"-->
<!--                 placeholder="Keresés..."/>-->
<!--      </b-field>-->
      <b-field>
        <b-tag class="mr-1">Összesen: {{ rowCount }}</b-tag>
        <b-tag class="mr-1 has-text-success" type="is-success is-light">Aktív: {{ activeInspectionCount }}</b-tag>
        <b-tag class="mr-1" type="is-danger is-light">Törölt: {{ deletedInspectionCount }}</b-tag>
      </b-field>
    </template>

    <template #detail="props">
      <InspectionPlanUniversalTable v-if="!!props.row.sub_table"
                                    :table-columns="props.row.sub_table.columns"
                                    :table-data="props.row.sub_table.data"
                                    :is-detailed="props.row.sub_table.is_detailed"/>
      <div v-else>Erről a sorról nem érhető el részletesebb információ</div>
    </template>
  </b-table>
</template>

<script>

import { formattedDate } from '@/utils/utils'

export default {
  name: 'InspectionPlanUniversalTable',
  props: {
    tableData: {
      type: Array,
      required: true,
    },
    tableColumns: {
      type: Array,
      required: true,
    },
    isDetailed: {
      type: Boolean,
      default: false,
    },
  },
  data () {
    return {
      defaultOpenedDetails: [],
      itemPerPage: 20,
    }
  },
  methods: {
    formattedDate,
    dynamicFunction (functionName, row) {
      alert(`dynamicFunction: ${functionName}, row: ${JSON.stringify(row)}`)
      this.$emit('dynamicFunction', {
        functionName: 'testFunctionName',
        payload: row,
      })
    },
    getRowStatusTagType (status) {
      return status === 'active'
        ? 'is-success'
        : status === 'deleted'
          ? 'is-danger'
          : 'is-info'
    },
    getRowStatusText (status) {
      return status === 'active'
        ? 'Aktív'
        : status === 'deleted'
          ? 'Törölt'
          : status
    },
    getRowFunctionTitle (functionName) {
      return functionName === 'PLAN_SELECTION'
        ? 'Kiválasztás'
        : functionName
    },
    getRowFunctionIcon (functionName) {
      return functionName === 'PLAN_SELECTION'
        ? 'check'
        : ''
    },
  },
  computed: {
    rowCount () {
      return this.tableData.length
    },
    activeInspectionCount () {
      return this.tableData.filter(item => item.status === 'active').length
    },
    deletedInspectionCount () {
      return this.tableData.filter(item => item.status === 'deleted').length
    },
  },
}
</script>

<style scoped>

>>> .table tr.detail .detail-container {
  padding: 0 !important;
}

>>> .table tr.detail td {
  padding-top: 0 !important;
}

</style>
